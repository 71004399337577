import { Link } from "gatsby"

import { default as React } from "react"
import parse from "html-react-parser"

import {
  connectStateResults,
  Highlight,
  Hits,
  Index,
} from "react-instantsearch-dom"
const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits
  return hitCount > 0 ? (
    <div className="HitCount mb-5">
      {hitCount} result{hitCount !== 1 ? `s` : ``}
    </div>
  ) : null
})
const PageHit = ({ hit }) => (
  <article
    className="blog-latest mb-5 wp-block-columns"
    itemScope
    itemType="http://schema.org/Article"
  >
    {hit.image && (
      <div
        className="wp-block-column wrap-33-m"
        style={{ flexBasis: "33.33%" }}
      >
        <Link to={hit.uri}>
          <div
            className="article-thumbs img-fit"
            alt={hit.title}
            style={{ backgroundImage: `url(${hit.image})` }}
          />
        </Link>
      </div>
    )}
    <div className="wp-block-column wrap-66-m" style={{ flexBasis: "66.66%" }}>
      <h2 className="h5">
        <Link to={hit.uri} itemProp="url">
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </Link>
      </h2>
      <span style={{ color: "#999" }}>{hit.date}</span>
      <p className="blog-meta">
        {hit.hosts.map(host => (
          <Link to={"/host/" + host.slug}>{host.name}</Link>
        ))}
      </p>
      <section itemProp="description">
        {parse(hit._highlightResult["description"].value)}
      </section>
    </div>
  </article>
)
const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <HitCount />
    <Hits className="Hits" hitComponent={PageHit} />
  </Index>
)
const SearchResult = ({ indices, className }) => (
  <div className={className}>
    {indices.map(index => (
      <HitsInIndex index={index} key={index.name} />
    ))}
  </div>
)
export default SearchResult
