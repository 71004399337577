import React from "react"
import { connectSearchBox } from "react-instantsearch-dom"
import { FaSearch } from "react-icons/fa"
export default connectSearchBox(
  ({ refine, currentRefinement, className, onFocus }) => (
    <form className={className}>
      <div className="form-group has-search">
        <FaSearch className="SearchIcon form-control-feedback" />
        <input
          className="SearchInput form-control"
          type="text"
          placeholder="Search"
          aria-label="Search"
          onChange={e => refine(e.target.value)}
          value={currentRefinement}
          onFocus={onFocus}
        />
      </div>
    </form>
  )
)
