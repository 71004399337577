import React from "react"
import { graphql } from "gatsby"
import Search from "../components/search"
import Layout from "../components/layout"
import Seo from "../components/seo"

const searchIndices = [{ name: `pointOfInquiry`, title: `pointOfInquiry` }]

const SearchPage = ({ data: { page } }) => {
  return (
    <Layout>
      <Seo title={page.title} description={page.excerpt} />
      <main
        id="main"
        className="site-main no-stretch dft-padding search"
        role="main"
      >
        <header className="entry-header">
          <h1 className="entry-title">Search</h1>
          <hr />
        </header>
        <Search indices={searchIndices} />
      </main>
    </Layout>
  )
}

export default SearchPage

export const pageQuery = graphql`
  query GET_SEARCH_PAGE {
    page: wpPage(template: { templateName: { eq: "Search Page" } }) {
      title
      content
      databaseId
      uri
      slug
      smartcrawlSeo {
        metaDesc
        title
        opengraphTitle
        opengraphDescription
        opengraphImage {
          mediaItemUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          mediaItemUrl
        }
      }
      featuredImage {
        node {
          mediaItemUrl
        }
      }
    }
  }
`
